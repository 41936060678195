'use strict';

angular.module('informaApp')
    .directive('infModalInfo', function () {
        return {
            restrict: 'E',
            templateUrl: 'directives/inf-modal-info/template.ptl.html',
            scope: {
                className: "=?",
                textInfo: "=",
                modalId: "=?"
            },
            link: function (scope, element, attr) {
            }
        }
    });